/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import Thumbnail from 'static/fh-favicon.png';
import { url, themeColor } from 'data/config';

export default ({ data, pageContext }) => {
  const { locale = 'en' } = pageContext;
  const pageName = pageContext?.page
    ? pageContext.page.charAt(0).toUpperCase() + pageContext.page.slice(1)
    : '404';
  const cms = data[`datoCms${pageName}`];
  const seo = data.seoData.globalSeo;
  const pageTitle = cms?.seo[0]?.metaTags?.title;
  const title =
    pageTitle && pageContext.page !== 'homepage' ? `${pageTitle} | ${seo.siteName}` : seo.siteName;

  return (
    <Helmet>
      <meta name="description" content={seo.fallbackSeo.description} />
      <meta name="image" content={Thumbnail} />

      <meta property="og:url" content={`${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={seo.fallbackSeo.description} />
      <meta property="og:image" content={Thumbnail} />

      <meta name="theme-color" content={themeColor} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={seo.siteName} />
      <meta name="twitter:image:src" content={Thumbnail} />

      <title>{title}</title>
      <html lang={locale} dir="ltr" />
    </Helmet>
  );
};
