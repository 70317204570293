module.exports = {
  siteTitle: 'Page title',
  siteDescription: 'Default page description',
  logo: 'Logo url',
  author: 'FlyHigh.PRO',
  url: 'https://flyhigh.pro',
  legalName: 'FlyHigh.PRO',
  themeColor: '#003aff',
  backgroundColor: '#ffffff',
  faviconPath: `./static/fh-favicon.png`, // for example: ./static/favicon/favicon-512.png
  foundingDate: '2021',
};
