import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { Link as SmoothScrollLink } from 'react-scroll';
import * as styles from 'styles/menu.module.scss';

const MenuOverlay = ({ isMenuOpen, setMenuOpen, data, location, pageContext }) => {
  const { team, menu, menuText } = data?.datoCmsHeader;
  const year = new Date().getFullYear();
  const copyrightText = menuText.replace('{year}', year);
  const locales = false;
  // if you want to show lang switcher, uncomment this line and remove line above
  // const { locales } = data?.datoCmsSite;
  const { locale } = pageContext;
  const defaultLang = locales[0];
  const isDefaultLang = (l) => l === defaultLang;
  const isHomepage = (p) => p === `/${locale}` || p === '/';

  return (
    <div
      className={classNames({
        [`${styles.menuOverlay}`]: true,
        [`${styles.open}`]: isMenuOpen,
      })}
    >
      <div className="page-container">
        {team && (
          <div className={styles.team}>
            {team.map((member) => (
              <div className={styles.teamMember} key={member.email}>
                <Img fluid={member.photo.fluid} className={styles.photo} />
                <div className={styles.detailsWrapper}>
                  <a href={`mailto:${member.email}`}>{member.email}</a>
                  <a href={`tel:${member.phone.split(' ').join('')}`}>{member.phone}</a>
                </div>
              </div>
            ))}
          </div>
        )}
        <nav className={styles.menuWrapper}>
          {menu && (
            <ul
              className={classNames({
                [`${styles.menu}`]: true,
                [`${styles.open}`]: isMenuOpen,
              })}
            >
              {menu.map((menuItem) => {
                const link =
                  isHomepage(location.pathname) && menuItem.link.startsWith('/#')
                    ? menuItem.link.substring(1)
                    : menuItem.link;

                return (
                  <li key={link}>
                    {link.startsWith('/') && (
                      <Link to={link} onClick={() => setMenuOpen(false)}>
                        {menuItem.text}
                      </Link>
                    )}

                    {link.startsWith('#') && (
                      <SmoothScrollLink
                        to={link.replace('#', '')}
                        offset={-20}
                        onClick={() => setMenuOpen(false)}
                      >
                        {menuItem.text}
                      </SmoothScrollLink>
                    )}

                    {!link.startsWith('/') && !link.startsWith('#') && (
                      <a href={link} onClick={() => setMenuOpen(false)}>
                        {menuItem.text}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
        <div className={styles.bottomWrapper}>
          <span>{copyrightText}</span>
          {locales && (
            <ul className={styles.langSwitcher}>
              {locales.map((lang) => {
                const pathname = location.pathname.replace(`/${locale}`, '');
                return (
                  <li key={lang}>
                    <Link
                      onClick={() => lang !== locale && setMenuOpen(false)}
                      to={`${!isDefaultLang(lang) ? `/${lang}` : ''}${pathname}`}
                      className={classNames({
                        [`${styles.active}`]: lang === locale,
                      })}
                    >
                      {lang}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuOverlay;
