import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Logo from 'assets/icons/fh-logo.svg';
import * as styles from 'styles/header.module.scss';
import siteContext from 'src/store/context';
import Mail from 'assets/icons/icon-mail.svg';
import MenuOverlay from './MenuOverlay';

const Header = ({ data: cmsData, location, pageContext }) => {
  if (!cmsData?.datoCmsHeader) return null;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => setMenuOpen((prev) => !prev);

  const { setCursorColor } = useContext(siteContext);
  const hoverIn = () => isMenuOpen && setCursorColor('WHITE');
  const hoverOut = () => setCursorColor('BLUE');

  return (
    <header
      className={classNames({
        [`${styles.siteHeader}`]: true,
        [`${styles.open}`]: isMenuOpen,
      })}
      onMouseOver={hoverIn}
      onFocus={hoverIn}
      onMouseOut={hoverOut}
      onBlur={hoverOut}
    >
      <div className="page-container">
        <div className={styles.logoWrapper}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.navbarNav}>
          {location.pathname !== '/contact' && (
            <Link to="/contact" className={styles.contactLink}>
              <Mail />
            </Link>
          )}
          <button
            type="button"
            onClick={openMenu}
            className={classNames({
              [`${styles.siteMenuToggle}`]: true,
              [`${styles.open}`]: isMenuOpen,
            })}
          >
            <div className={styles.icon}>
              <div className={styles.bar} />
              <div className={styles.bar} />
              <div className={styles.bar} />
            </div>
          </button>
        </div>
      </div>
      <MenuOverlay
        data={cmsData}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
        location={location}
        pageContext={pageContext}
      />
    </header>
  );
};

export default Header;
