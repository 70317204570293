// extracted by mini-css-extract-plugin
export var active = "menu-module--active--BddIt";
export var bottomWrapper = "menu-module--bottomWrapper--wRWZA";
export var detailsWrapper = "menu-module--detailsWrapper--rBGpc";
export var langSwitcher = "menu-module--langSwitcher--ZPfcy";
export var menu = "menu-module--menu--PuVK2";
export var menuOverlay = "menu-module--menuOverlay--7ybqO";
export var menuWrapper = "menu-module--menuWrapper--UlcHk";
export var open = "menu-module--open--PFZ4b";
export var photo = "menu-module--photo--rOTPd";
export var team = "menu-module--team--PP2OF";
export var teamMember = "menu-module--teamMember--LX-KX";