import React, { useEffect, useState } from 'react';
import AnimatedCursor from 'react-animated-cursor';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import SiteContext, { CURSOR_COLORS } from 'src/store/context';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SEO from 'layout/SEO';

export default ({ children, ...props }) => {
  const [cursorColor, setCursorColor] = useState('BLUE');
  const [deviceType, setDeviceType] = useState('');
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    setDeviceType(
      window.matchMedia('(hover: hover) and (pointer: fine)').matches ? 'desktop' : 'mobile'
    );

    const handleScroll = () => {
      setShowChat(true);
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    const delay = setTimeout(() => {
      setShowChat(true);
    }, 10000); // Delay for 2 seconds

    return () => {
      clearTimeout(delay);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SiteContext.Provider value={{ cursorColor, setCursorColor }}>
      <SEO {...props} />
      <Header {...props} />
      {children}
      <Footer {...props} />
      {deviceType === 'desktop' && (
        <AnimatedCursor
          color={CURSOR_COLORS[cursorColor]}
          outerAlpha={0}
          innerSize={12}
          innerScale={1.5}
        />
      )}
      {showChat && (
        <TawkMessengerReact propertyId="648ae14ecc26a871b022af32" widgetId="1h2v806mo" />
      )}
    </SiteContext.Provider>
  );
};
