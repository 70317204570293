import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from 'styles/footer.module.scss';

const Footer = ({ data }) => {
  let cmsData;
  if (!data?.datoCmsFooter) {
    const query = useStaticQuery(graphql`
      query FooterQuery {
        datoCmsFooter(locale: { eq: "en" }) {
          locale
          reviewLabel
          reviewMedia {
            url
            alt
            fluid {
              base64
              aspectRatio
              height
              sizes
              src
              srcSet
              tracedSVG
              width
            }
          }
          footerLinks {
            title
            link
          }
          reviewLink
        }
      }
    `);
    cmsData = query.datoCmsFooter;
  } else {
    cmsData = data.datoCmsFooter;
  }
  const { footerLinks, reviewLabel, reviewMedia, reviewLink } = cmsData;
  return (
    <>
      <footer className={styles.footer}>
        <div className="page-container">
          <div className={styles.socials}>
            <ul className={styles.socialsLinks}>
              {footerLinks &&
                footerLinks.map((link) => (
                  <li key={link.title}>
                    <a href={link.link} target="_blank" rel="noreferrer">
                      {link.title}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.review}>
            <div className={styles.reviewLabel}>{reviewLabel}</div>
            {reviewMedia && (
              <div className={styles.reviewLinkWrapper}>
                <a href={reviewLink} className={styles.reviewLink} target="_blank" rel="noreferrer">
                  <Img fluid={reviewMedia.fluid} />
                </a>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
